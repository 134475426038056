<template>
  <div class="col-lg-12" v-show="currentPosition">
    <div class="row">
      <div class="col-lg-12">
        <h4>Manage Custom Scripts for {{ currentPositionName }}</h4>
        <p>Manage custom scripts used to label custom position drill videos.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12" v-if="scripts.length > 0">
        <ul class="list-group mb-4">
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
            v-for="{name, id: scriptId} in scripts"
            :ref="`custom_script_${scriptId}`"
            :key="scriptId"
          >
            <input v-if="editForm && editForm.id === scriptId" v-model="editForm.name" class="form-control" />
            <span v-else>{{ name }}</span>
            <button v-if="editForm && editForm.id === scriptId" type="button" class="btn btn-link" @click.prevent="() => saveScriptTitle(scriptId)">Save</button>
            <button v-else type="button" class="btn btn-link" @click.prevent="() => editScriptTitle(scriptId)">Edit</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <h5>Add Custom Script:</h5>
        <form @submit.prevent="createScript">
          <div class="form-group">
            <label for="exampleInputEmail1">Script Name</label>
            <input type="text" class="form-control" id="customScriptName" v-model="form.name" required>
          </div>
          <button type="submit" class="btn btn-primary">
            {{ saving ? 'Saving...' : 'Create' }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  props: {
    player: Object,
    position: {
      type: [Number, String],
      default: undefined,
    },
  },
  watch: {
    position(val, oldVal) {
      this.fetchCustomScripts();
    }
  },
  computed: {
    currentPositionName: function () {
      if (this.position === undefined) {
        return 'Not Found';
      }

      const positionId = Number(this.position)
      const position = this.player.profile.data.positions.data.find(p => p.id === positionId)

      return position === undefined ? 'Not Found' : position.name;
    },
    currentPosition: function () {
      if (this.position === undefined) {
        return undefined;
      }

      const positionId = Number(this.position)

      return this.player.profile.data.positions.data.find(p => p.id === positionId)
    }
  },
  data() {
    return {
      scripts: [],
      saving: false,
      form: {
        name: '',
        description: "This is a custom drill that's only available to you.",
      },
      editForm: null,
    };
  },
  methods: {
    saveScriptTitle() {
      if (!this.editForm) {
        return;
      }

      axios.patch(`/api/script-crud/${this.editForm.id}`, { name: this.editForm.name })
        .then(response => {
          const { id, name } = response.data.data;
          const idx = this.scripts.findIndex(s => s.id === id);
          this.scripts[idx] = response.data.data;
          toastr.success('Custom video updated')
        }).catch(() => {
          toastr.error('There was an error. Please try again.')
        }).finally(() => {
          this.editForm = null;
        });
    },
    editScriptTitle(scriptId) {
      const script = this.scripts.find(({id}) => id === scriptId);
      const { id, name } = script;
      this.editForm = { id, name };
    },
    fetchCustomScripts() {
      if (this.currentPosition === undefined) {
        return
      }

      axios.get('/api/script-crud', {
        params: {
          user_id: this.player.id,
          position_id: this.position,
        }
      }).then((response) => {
        if (response.data.data.length === 0) {
          return;
        }

        this.scripts = response.data.data.filter((script) => script.custom === true);
      }).catch((...a) => {
        console.log('it failed!', a);
      })
    },
    createScript() {
      if (this.currentPosition === undefined) {
        return;
      }

      this.saving = true;

      axios.post('/api/script-crud', {
        name: this.form.name,
        user_id: this.player.id,
        position: this.currentPosition.shortcode,
        description: this.form.description,
      }).then(({ data: responseBody }) => {
        this.$emit('scriptCreated', responseBody.data);
        toastr.success('Custom script created.');
      }).catch(() => {
        toastr.error('There was an issue creating the custom script. Please try again.');
      }).finally(() => {
        this.saving = false
        this.form.name = ''
        this.fetchCustomScripts();
      })
    }
  },
}
</script>
