<template>
  <div class="col-lg-12">
    <data-tables :data="events" :total="eventsTotal" v-if="apiData" :action-col="actionCol">
      <el-table-column prop="event.title" label="Event Name" sortable="custom"/>
      <el-table-column prop="jersey_number" label="Jersey Number" />
      <el-table-column prop="standout" label="Standout?" sortable="custom"/>
      <el-table-column prop="event.start_date" label="Start Date" sortable="custom"/>
      <el-table-column prop="event.end_date" label="End Date" sortable="custom"/>
    </data-tables>
    <b-button v-b-modal.add-attendee-modal variant="primary" v-if="eventDataLoaded && eventData.length > 0">
      <b-icon-calendar-plus-fill class="mr-2"/>
      Add Event
    </b-button>
    <b-modal id="add-attendee-modal" title="Add Event Attendance" @ok="handleSubmit">
      <template #modal-ok>Submit</template>
      <form v-if="eventSelectOptions.length > 0">
        <div class="form-group">
          <label for="event_id">Event</label>
          <b-form-select v-model="attendeeModal.event" :options="eventSelectOptions"
                         :state="validateModalState('event')" required>
            <template #first>
              <b-form-select-option :value="null" disabled>Please select an event</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback :state="validateModalState('event')">
            Selecting an event is required.
          </b-form-invalid-feedback>
        </div>
        <div class="form-group">
          <label for="jersey_number">Jersey Number</label>
          <b-form-input name="jersey_number"
                        v-model="attendeeModal.jersey_number"
                        type="text"
                        :state="validateModalState('jersey_number')"></b-form-input>
          <b-form-invalid-feedback :state="validateModalState('jersey_number')">
            {{ attendeeModalErrors.jersey_number && attendeeModalErrors.jersey_number[0] }}
          </b-form-invalid-feedback>
        </div>
        <div class="form-group">
          <label for="standout">Standout?</label>
          <b-form-checkbox v-model="attendeeModal.standout" name="standout" switch/>
        </div>
      </form>
      <div v-else>
        Loading...
      </div>
    </b-modal>

    <!-- TODO: ability to edit event data -->
    <!-- TODO: see https://njleonzhang.github.io/vue-data-tables/#/en-us/actionCol -->
    <!--    <ul class="list-group" v-if="apiData" >-->
    <!--      <li class="list-group-item" v-for="event in events">{{ event.event.title }}</li>-->
    <!--    </ul>-->
  </div>
</template>

<script>
// Note: this component was built in haste to get the initial version out.
// The various pieces could use some better separation, naming, and extracting
// pieces of it into separate, smaller components.

const DataTables = require('vue-data-tables').DataTables;
export default {
  name: 'EventsTable',
  props: ['user_id', 'sport_id'],
  components: {
    DataTables,
  },
  data() {
    return {
      url: `/api/v2/user/${this.user_id}/event-attendee`,
      attendeeModal: {
        event: null,
        jersey_number: null,
        standout: false,
      },
      attendeeModalErrors: {},
      apiData: null,
      eventData: [],
      eventDataLoaded: false,
      loading: false,
      actionCol: {
        label: 'Actions',
        props: {
          align: 'center',
        },
        buttons: [
          // TODO: uncomment when building the edit feature
          // {
          //   props: {
          //     type: 'primary',
          //     icon: 'el-icon-edit'
          //   },
          //   handler: row => {
          //     this.$message({
          //       message: 'Edit coming soon!',
          //       type: 'warning',
          //     })
          //   },
          //   label: 'Edit'
          // },
          {
            label: 'Delete',
            handler: async row => {

              if (!confirm('Are you sure you want to delete this event?')) {
                return;
              }

              try {
                await axios.delete(`/api/v2/user/${this.user_id}/event-attendee/${row.id}/`);


                // Refresh the table and remaining events.
                this.loadData().then(() => {
                  this.$message({
                    message: 'Event Attendance was deleted successfully.',
                    type: 'success',
                  });
                });
                this.loadEventOptions();
              } catch (error) {
                this.$message({
                  message: 'There was an issue deleting the event attendance for this user.',
                  type: 'error',
                });
              }
            }
          }]
      }
    }
  },
  computed: {
    events: v => _.get(v, 'apiData', []),
    eventsTotal: v => v.apiData.length,
    eventSelectOptions: v => v.eventData.map(v => {
      return {value: v.id, text: v.title};
    }),
    count: v => _.get(v, 'apiData.total', 0)
  },
  methods: {
    validateModalState(field) {
      return this.hasModalError(field) ? false : null;
    },
    hasModalError(field) {
      return this.attendeeModalErrors.hasOwnProperty(field);
    },
    async handleSubmit(e) {
      // Prevent modal from closing until we get a valid response
      e.preventDefault();

      if (this.attendeeModal.event === null) {
        this.$set(this.attendeeModalErrors, 'event', ["Event selection is required."]);
        return;
      }

      try {
        const response = await axios.post(`/api/v2/user/${this.user_id}/event-attendee`, {
          event_id: this.attendeeModal.event,
          jersey_number: this.attendeeModal.jersey_number,
          standout: this.attendeeModal.standout,
        });

        // on success, hide the modal
        this.$nextTick(() => {
          this.attendeeModal.event = null;
          this.attendeeModal.jersey_number = null;
          this.attendeeModal.standout = false;
          this.$set(this, 'attendeeModalErrors', {});
          this.$bvModal.hide('add-attendee-modal');
          this.loadData().then(() => {
            this.$message({
              type: 'success',
              message: 'Event attendance was added successfully.',
            })
          });
          this.loadEventOptions();
        });
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.attendeeModalErrors = error.response.data.errors;
        }
        console.log('error happened', error);
      }
    },
    async loadData() {
      this.loading = true
      const response = await axios.get(this.url)
      this.apiData = response.data
      this.loading = false
    },
    addEventAttendee(e) {
      this.$message('add event clicked.');
    },
    async loadEventOptions() {
      const response = await axios.get(`/api/v2/user/${this.user_id}/available-events?sport_id=${this.sport_id}`);
      this.eventData = response.data;
      this.eventDataLoaded = true;
    }
  },
  created() {
    this.loadData();
    this.loadEventOptions();
  }
}
</script>
