<template>
  <div class="stat-item text-center" v-if="adminViewOnly ? currentRole === 'super admin' : true">
    <div type="button" class="stat-key" :data-toggle="type !== 'academics' ? 'modal' : null" :data-target="'#measurablesModal' + attribute">
      <div class="stat-title">
      {{ displayName }}
      <i class="mdi mdi-history" v-if="type !== 'academics' ? 'modal' : null"></i>
      </div>
    </div>
    <div class="stat-value">
      {{ dataValue }}
      <span v-for="file in files" v-if="file.attribute_name === attribute">
        <AttributeMediaGallery
          :key="attribute + '-' + file.type"
          :type="file.type"
          :attribute="attribute.replace(/_/g, ' ')"
          :url="file.file_upload.data.optimized_url || file.file_upload.data.url"
          :newUpload="file.new_upload ? true : false"
        ></AttributeMediaGallery>
      </span>
      <div>
        <button
          class="btn btn-small"
          @click="showUploadModal()"
          v-if="currentRole === 'super admin'"
        ><i class="fa fa-pencil"></i> Edit</button>
      </div>
    </div>
    <!-- TODO: Move the modal into a component maybe -->
    <div class="modal fade" :id="'measurablesModal' + attribute" tabindex="-1" role="dialog" aria-labelledby="measurablesModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="measurablesModalLabel">{{ displayName }} History</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-5 offset-sm-1 pr-0 text-left">
                <p class="stat-revisions-created pl-3 pb-3 mb-3 border-bottom" v-if="revisions" v-for="(value) in revisions">{{new Date(value.created_at).toLocaleDateString('en-us') || 'Cleared'}}</p>
              </div>
              <div class="col-sm-5 pl-0 text-right">
                <p class="stat-revisions-value pr-3 pb-3 mb-3 border-bottom" v-if="revisions" v-for="(value) in revisions">{{formatRevision(attribute, value.new_value) || 'Cleared'}}</p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-primary">Show More</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerStat',
  mounted: function() {
    // listen for the attributeUpdated event from the parent component:
    this.$parent.$on('attributeUpdated', (data) => {
      if (data.attribute === this.attribute) {
        this.dataValue = data.value
      }
    })
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    attribute: {
      type: String,
      default: ''
    },
    displayName: {
      type: String,
      default: ''
    },
    files: {
      type: Array,
      default: () => []
    },
    revisions: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ''
    },
    adminViewOnly: {
      type: Boolean,
      default: true
    },
    currentRole: {
      type: String,
      default: 'coach'
    }
  },
  data() {
    return {
      dataValue: this.value,
    }
  },
  methods: {
    formatRevision(key, value) {
      return window.Helpers.measurements.formatMeasurementRevision(key, value)
    },
    showUploadModal() {
      this.$emit('showUploadModal', {attribute: this.attribute, value: this.dataValue, files: this.files})
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-small {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  font-family: 'urw-din', sans-serif;

  &:hover {
    background-color: #E7F816;
  }

}
</style>
