<template>
  <PlayerStatTilesPanel>
    <div v-for="testingAttribute in testingAttributes">
      <PlayerStatTile
        :stat-name="testingAttribute.name"
        :stat-value="testingAttribute.value"
        :thumbnail-image="testingAttribute.thumbnailImage"
        :video="testingAttribute.video"
      />
    </div>
  </PlayerStatTilesPanel>
</template>

<script>
import PlayerStatTile from '@components/PlayerProfile/PublicProfile/components/PlayerStatTile';
import PlayerStatTilesPanel from '@components/PlayerProfile/PublicProfile/components/PlayerStatTilesPanel';

export default {
  name: "TestingPanel",
  components: { PlayerStatTilesPanel, PlayerStatTile },
  inject: ['player'],
  computed: {
    testingAttributes() {
      const data = Object.values(this.player.profile.testing).filter(stat => {
        if (!stat.value) {
          return false;
        }

        return stat.value !== 'N/A'
      })

      return data.map((stat) => {
        return {
          name: stat.name,
          value: stat.value,
          thumbnailImage: stat.attribute?.file_upload?.thumbnail_path,
          video: stat.attribute?.file_upload?.path
        }
      })
    }
  },
}
</script>
