<template>
  <span :class="newUpload ? 'new-file-upload' : ''">
    <div style="display:none" :id="'video' + this.$vnode.key">
      <video class="lg-video-object lg-html5" controls="" preload="none">
        <!-- <source :src="#"> -->
        <source src="#">
        Your browser does not seem to support this HTML5 video.
      </video>
    </div>

    <span v-if="type === 'video'">
      <span v-if="!checkExtension">
        <img v-on:click="modal()" class="attribute-media" src="/build/images/video_icon32.png" style="height:16px; width:16px" alt="view">

        <!-- Modal -->
        <div class="modal fade attribute-modal" :id="'mediaModal' + this.$vnode.key" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content attribute-modal-content video-card modal-border-themed">
              <div class="modal-body attribute-video-body">
                <div class="heading">
                  <div class="text-center">
                    <p class="text-uppercase title">{{ this.attribute != null ? this.attribute.toUpperCase() : '' }}</p>
                  </div>
                </div>
                <div class="video-wrapper">
                  <video class="video-js vjs-fluid" :data-setup='videoSetup'>
                    <source :src="url"></source>
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>

      <a v-else :href="url" download>
        <img class="attribute-media" src="/build/images/video_icon32.png" style="height:16px; width:16px" alt="download" title="View">
      </a>
    </span>

    <a  v-if="type === 'image'" :href="url"  :id="'lightgallery' + this.$vnode.key" :data-sub-html="attribute">
      <img class="attribute-media" src="/build/images/img_icon32.png" style="height:16px; width:16px" alt="view" title="View Image">
    </a>

    <a  v-if="type === 'transcript'" :href="url"  :id="'lightgallery' + this.$vnode.key" :data-sub-html="attribute">
      VIEW
    </a>
  </span>
</template>

<script>
  export default {

    name: 'AttributeMedia',
    props: {
      type: {
        type: String,
        required: true
      },
      url: {
        type: String,
        required: true
      },
      attribute: {
        type: String,
        required: true
      },
      newUpload: {
        type: Boolean,
        required: false,
        default: false
      },
      shouldLoop: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
        videoShowing: false,
        loaded: false,
        videoPlayer: null
      }
    },
    mounted: function() {
      $('#lightgallery' + this.$vnode.key).lightGallery({
        selector: 'this'
      });
      const self = this
      $('.modal', this.$el).on('hide.bs.modal', function() {
          self.toggleVideo()
      })
    },
    computed: {
      checkExtension: function() {
        const file = this.url;
        const extension = file.split('.').pop();

        return extension === 'qt' || extension === 'mov';
      },
      videoSetup() {
        return JSON.stringify({"fluid": true, "controls": true, "autoplay": false, "preload": "auto", "muted": false, "loop": this.shouldLoop})
      }
    },
    methods: {
      modal: function() {
        $('#mediaModal'+this.$vnode.key).modal('toggle');
        this.toggleVideo()
      },
      toggleVideo: function (event) {
        const self = this
        const $video = $('video', self.$el)[1]
        this.videoShowing = ! this.videoShowing
        if (this.videoShowing) {
          // this.$emit('video-toggle', this.video)
          setTimeout(() => {
            let $button = $('.showVideoButton', self.$el)
            $($button).val('Hide')
            self.videoPlayer = videojs($video)
            self.videoPlayer.ready(function() {
              const video = this
              self.loaded = true
              $video.play()
              this.currentTime(video.start_time)
              const $currentTimeToolTip = $('.vjs-play-progress .vjs-time-tooltip', self.$el).first();
              $currentTimeToolTip.after(`<span class="mwi-time-tooltip"></span>`);
              const $timeLeft = $('.vjs-remaining-time-display', self.$el).first();
              const $mwiTimeToolTip = $('.mwi-time-tooltip', self.$el).first();

              setInterval(() => {
                const whereYouAt = self.videoPlayer.currentTime().toFixed(2);
                $timeLeft.html(`<span>${((whereYouAt))}</span>`);
                $mwiTimeToolTip.text(whereYouAt);
                $mwiTimeToolTip.css('right', $currentTimeToolTip.css('right'));
              }, 100);
            })
          }, 200)
        }
        else {
          $video.pause();
          // let $button = $('.showVideoButton.video_' + this.video.id)
          // $($button).val('View')
        }
      }
    }

  }
</script>


<style lang="scss" scoped>

  /* .video-wrapper {
    height: 500px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .video-wrapper > video {
    width: 100%;
  } */
  @-webkit-keyframes glow {
    from {
      border-color: #000;
      box-shadow: 0 0 100px -100px #000;
      -webkit-box-shadow: 0 0 100px -100px #000;
      -moz-box-shadow: 0 0 100px -100px #000;
    }
    to {
      border-color: #E7F816;
      box-shadow: 0 0 100px -100px #E7F816;
      -webkit-box-shadow: 0 0 100px -100px #E7F816;
      -moz-box-shadow: 0 0 100px -100px #E7F816;
    }
  }

  @keyframes glow {
    from {
      border-color: #000;
      box-shadow: 0 0 20px -20px #000;
      -webkit-box-shadow: 0 0 20px -20px #000;
      -moz-box-shadow: 0 0 20px -20px #000;
    }
    to {
      border-color: #E7F816;
      box-shadow: 0 0 20px -20px #E7F816;
      -webkit-box-shadow: 0 0 20px -20px #E7F816;
      -moz-box-shadow: 0 0 20px -20px #E7F816;
    }
  }

  .new-file-upload {
    -webkit-animation: glow 1s infinite alternate;
    animation: glow 1s infinite alternate;
    transition: border 1s linear, box-shadow 1s linear;
  }

  .modal-dialog {
    display: flex;
    justify-content: center;
  }

  .attribute-modal-content {
    max-width: 50%;
  }

  .attribute-video-body {
    padding: 0 !important;
  }

  .video-js .vjs-play-progress .vjs-time-tooltip {
    color: rgba(255, 255, 255, 0) !important;
    background-color: rgba(155,155,155,0) !important;
  }

  .video-js .mwi-time-tooltip {
    color: #000;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0.3em;
    color: #000;
    float: right;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    padding: 6px 8px 8px 8px;
    pointer-events: none;
    position: absolute;
    top: -3.4em;
    visibility: hidden;
    z-index: 1;
  }

  .video-js .vjs-progress-control:hover .mwi-time-tooltip,
  .video-js .vjs-progress-control:hover .vjs-progress-holder:focus .mwi-time-tooltip {
    display: block;
    font-size: 0.6em;
    visibility: visible;
  }

  @media (max-width: 993px) {
    .attribute-modal-content {
      max-width: 80%;
    }
  }


  @media (max-height: 870px) {
    .attribute-modal-content {
      max-width: 40%;
    }
  }

  @media (max-width: 993px) and (max-height:870px) {
    .attribute-modal-content {
      max-width: 70%;
    }
  }

  @media (max-height: 725px) {
    .attribute-modal-content {
      max-width: 30%;
    }
  }

  @media (max-width: 993px) and (max-height:725px) {
    .attribute-modal-content {
      max-width: 50%;
    }
  }

</style>
