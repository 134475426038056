<template>
  <PlayerStatTilesPanel>
    <div v-for="measurable in measurables">
      <PlayerStatTile
        :stat-name="measurable.name"
        :stat-value="measurable.value"
        :thumbnail-image="measurable.thumbnailImage"
        :video="measurable.video"
      />
    </div>
  </PlayerStatTilesPanel>
</template>

<script>
import PlayerStatTile from '@components/PlayerProfile/PublicProfile/components/PlayerStatTile';
import PlayerStatTilesPanel from '@components/PlayerProfile/PublicProfile/components/PlayerStatTilesPanel';

export default {
  name: "MeasurablesPanel",
  components: { PlayerStatTilesPanel, PlayerStatTile },
  inject: ['player'],
  computed: {
    measurables() {
      let data = Object.values(this.player?.profile?.measurables).filter(stat => {
        if (!stat.value) {
          return false;
        }

        return stat.value !== 'N/A'
      });

      return data.map((stat) => {
        return {
          name: stat.name,
          value: stat.value,
          thumbnailImage: stat.attribute?.file_upload?.thumbnail_path,
          video: stat.attribute?.file_upload?.path
        }
      })
    }
  }
}
</script>
