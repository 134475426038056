import { render, staticRenderFns } from "./PlayerView.vue?vue&type=template&id=0048e6e2&scoped=true"
import script from "./PlayerView.vue?vue&type=script&lang=js"
export * from "./PlayerView.vue?vue&type=script&lang=js"
import style0 from "./PlayerView.vue?vue&type=style&index=0&id=0048e6e2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0048e6e2",
  null
  
)

export default component.exports