<template>
  <div class="col-12 col-lg-6 col-xl-4 video-card-container" v-if="!videoDeleted && (video.file_upload.optimized_url || (video.file_upload.data && video.file_upload.data.optimized_url))">
    <div>
      <div class="video-card card-box">
        <span class="badge badge-primary pull-right" v-if="video.organization_id" data-toggle="tooltip" title="To your organization">private</span>
        <div class="heading">
          <div class="text-center">
            <p class="text-uppercase title">{{title}}</p>
            <input v-if="notLoggedIn" type="button" data-toggle="modal" :data-target="'#loggedOut'" :class="'btn btn-primary ' + 'showVideoButton ' + `video_${video.id}`" value="View">
            <input v-else type="button" v-on:click="toggleVideo" :class="'btn btn-primary ' + 'showVideoButton ' + `video_${video.id}`" value="View">
          </div>
        </div>
        <div class="videoBox" v-if="videoShowing">
          <div v-show="! loaded" class="loading" v-cloak>
            <span>
              <div class="lds-ring"><div></div><div></div><div></div><div></div></div><br>
              Download/Processing Video
            </span>
          </div>
          <video class="video-js vjs-fluid" :data-setup='videoSetup'>
            <!-- <source :src="'http://sds.loc/public/videos/5d3b4a4907e20.mp4'"></source> -->
            <source :src="this.isAttributeFile || this.isEndorsement ? video.file_upload.data.optimized_url : video.file_upload.optimized_url"></source>
          </video>
          <div v-if="role && role === 'super admin'"><button @click="deleteVideo" class="btn btn-danger mt-1" style="float:right;"><i class="fa fa-trash"></i> Delete</button></div>
        </div>
        <div class="mt-10" v-if="!isEndorsement && !unauthorized">
          <!-- <input type="button" v-on:click="openComments" :class="'far fa-comment-alt ' + `video_${video.id}`"> -->
          <button :class="'far fa-comment-alt ' + `video_${video.id}`" style="margin-left: 10px; border: 0px; background-color: white;" v-on:click="openComments"></button>
          <p style="margin-left: 18px; margin-bottom: 0px !important; font-family: Helvetica; font-size: 10px;">{{ commentCount }}</p>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" v-if="isModalVisible">
      <!-- <button type="button" v-on:click.self="isModalVisible = false" aria-label="Close" class="video-comments-close"><span v-on:click.self="isModalVisible = false" aria-hidden="true">×</span></button> -->
      <!-- <button class="video-comments-close btn" v-on:click="isModalVisible = false">x</button> -->
      <div class="backdrop" v-on:click.self="isModalVisible = false">
        <div class="video-comments-modal row" style="background-color: lightgrey!important; height: fit-content!important;">
          <button type="button" v-on:click.self="isModalVisible = false" aria-label="Close" class="video-comments-close"><span v-on:click.self="isModalVisible = false" aria-hidden="true">×</span></button>
          <div class="col-12 col-lg-4 col-xl-4 modal-content" style="height: fit-content!important; width: 750px;">
            <div class="videoBox">
              <div style="text-align: center;">
                <p class="modal-title" style="font-size: 18px;">{{this.playerName}}</p>
                <p class="modal-title" style="font-size: 12px;">{{video.owner_type == 'App\\Script' || video.owner_type == 'App\\Footage' ? video.owner.name : (video.attribute_name ? video.attribute_name.replace(/_/g, ' ').slice(3) : (this.isEndorsement ? `${video.endorser.name} from ${video.organization.name}` : video.owner.question))}}</p>
              </div>
              <video class="video-js vjs-fluid modal-video" :data-setup='videoSetup'>
                <source :src="this.isAttributeFile ? video.file_upload.data.optimized_url : video.file_upload.optimized_url"></source>
              </video>
            </div>
          </div>
          <div class="col-12 col-lg-8 col-xl-8">
            <div class="modal-comments">
              <p>{{ commentCount == 1 ? '1 Comment' : commentCount + ' Comments'}}</p>
              <textarea type="text" maxlength="25000" rows="5" v-model="commentInput" name="new-note" placeholder="Type your note here..." class="form-control note-control"></textarea>
              <div style="position: relative; background-color: lightgrey; height: 55px;">
                <button class="btn btn-primary waves-effect waves-light" style="position: absolute; right: 10px; top: 10px;" v-on:click="makeComment">{{ 'Comment' }}</button>
              </div>
              <div>
                <player-video-comment v-for="(comment, index) in comments" :key="comment.id" :comment="comment" :getComments="getComments" :authuser="authuser" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .video-js .vjs-play-progress .vjs-time-tooltip {
    color: rgba(255, 255, 255, 0) !important;
    background-color: rgba(155,155,155,0) !important;
  }

  .video-js .mwi-time-tooltip {
    color: #000;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0.3em;
    color: #000;
    float: right;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    padding: 6px 8px 8px 8px;
    pointer-events: none;
    position: absolute;
    top: -3.4em;
    visibility: hidden;
    z-index: 1;
  }

  .video-js .vjs-progress-control:hover .mwi-time-tooltip,
  .video-js .vjs-progress-control:hover .vjs-progress-holder:focus .mwi-time-tooltip {
    display: block;
    font-size: 0.6em;
    visibility: visible;
  }

  .video-comments-modal {
    width: fit-content;
    position: relative;
    margin: 0 auto;
  }

  .modal-content {
    position: relative;
    padding-left: 15px !important;
    padding-right: 0px !important;
    background-color: lightgrey;
    color: black;
    border: none !important;
  }

  .modal-title {
    font-family: KairosSans;
    font-weight: normal;
    padding: 5px;
    margin-bottom: 0!important;
  }

  .modal-comments {
    padding: 2.5vh;
    margin: 2.5vh 0.5vh;
    overflow-y: scroll;
    height: 75vh;
    background-color: whitesmoke;
  }

  .video-comments-close {
    background-color: #000;
    border-radius: 50%;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-size: 18px;
    height: 36px;
    margin: -1rem -1rem -1rem auto;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 0vw;
    text-shadow: none;
    width: 36px;
    z-index: 9999;
  }

  .modal-backdrop {
  /* z-index: 10000; */
  position: fixed;
  top: 0;
  left: 0;
  -webkit-animation-name: show;
          animation-name: show;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  height: 100vh;
  width: 100vw;
}

</style>

<script>
  import PlayerVideoComment from './PlayerVideoComment';
  import videojs from 'video.js';

  export default {
    name: 'PlayerVideo',
    props: ['video', 'shouldLoop', 'playerName', 'authuser', 'isAttributeFile', 'isEndorsement', 'unauthorized', 'role'],
    components: { PlayerVideoComment },
    data() {
      return {
        videoShowing: false,
        loaded: false,
        videoPlayer: null,
        isModalVisible: false,
        comments: [],
        commentInput: '',
        notLoggedIn: false,
        videoDeleted: false,
      }
    },
    computed: {
      videoSetup() {
        return JSON.stringify({"fluid": true, "controls": true, "autoplay": false, "preload": "auto", "muted": false, "loop": this.shouldLoop})
      },
      commentCount() {
        var count = 0
        for( i = 0; i < this.comments.length; i++ ) {
          if(this.comments[i].comment !== '[deleted]') {
            count++
          }
        }
        return count
      },
      title() {
        if (this.video.owner_type == 'App\\Models\\Script' || this.video.owner_type == 'App\\Models\\Footage') {
          return this.video.owner.name;
        } else if (this.video.attribute_name) {
          return this.video.attribute_name.replace(/_/g, ' ').slice(3);
        } else if (this.isEndorsement) {
          return `${this.video.endorser.name} from ${this.video.organization.name}`;
        } else {
          return this.video.owner.question;
        }
      },
      videoType() {
        if (this.isAttributeFile) {
          return 'AttributeFile';
        } else {
          return 'Video';
        }
      }
    },
    mounted () {
      this.getComments();
      if (this.loggedIn === false && this.loggedIn !== null) {
        this.notLoggedIn = true;
        this.$emit('show-login');
      }
    },
    methods: {
      toggleVideo() {
        this.videoShowing = ! this.videoShowing;
        if (this.videoShowing) {
          this.$emit('video-toggle', this.video)
          setTimeout(() => {
            let self = this
            let $button = $('.showVideoButton', self.$el)
            $($button).val('Hide')
            let $video = $('video', self.$el)
            self.videoPlayer = videojs($video[0])
            if (this.fullscreen) self.videoPlayer.enterFullWindow();
            self.videoPlayer.ready(function() {
              self.loaded = true
              $video[0].play()
              this.currentTime(self.video.start_time)
              const $currentTimeToolTip = $('.vjs-play-progress .vjs-time-tooltip', self.$el).first();
              $currentTimeToolTip.after(`<span class="mwi-time-tooltip"></span>`);
              const $timeLeft = $('.vjs-remaining-time-display', self.$el).first();
              const $mwiTimeToolTip = $('.mwi-time-tooltip', self.$el).first();

              setInterval(() => {
                //let fullLength = self.videoPlayer.duration();
                const whereYouAt = self.videoPlayer.currentTime().toFixed(2);
                $timeLeft.html(`<span>${(whereYouAt)}</span>`);
                $mwiTimeToolTip.text(whereYouAt);
                $mwiTimeToolTip.css('right', $currentTimeToolTip.css('right'));
              }, 100);
            })
          }, 200)
        } else {
          let $button = $('.showVideoButton.video_' + this.video.id)
          $($button).val('View')
        }
      },
      openComments: function (event) {
        this.isModalVisible = true

        this.$emit('video-toggle', this.video)
        setTimeout(() => {
          let self = this
          let $video = $('.modal-video', self.$el)
          self.videoPlayer = videojs($video[0])
          self.videoPlayer.ready(function() {
            self.loaded = true
            $video[0].play()
            this.currentTime(self.video.start_time)
            const $currentTimeToolTip = $('.vjs-play-progress .vjs-time-tooltip', self.$el);
            $currentTimeToolTip.after(`<span class="mwi-time-tooltip"></span>`);
            const $timeLeft = $('.vjs-remaining-time-display', self.$el);
            const $mwiTimeToolTip = $('.mwi-time-tooltip', self.$el);

            setInterval(() => {
              //let fullLength = self.videoPlayer.duration();
              const whereYouAt = self.videoPlayer.currentTime().toFixed(2);
              $timeLeft.html(`<span>${(whereYouAt)}</span>`);
              $mwiTimeToolTip.text(whereYouAt);
              $mwiTimeToolTip.css('right', $currentTimeToolTip.css('right'));
            }, 100);
          })
        }, 200)
      },
      makeComment: function() {
        if (!this.unauthorized) {
          axios.post('/api/comments', {
            'comment': this.commentInput,
            'commentable_id': this.video.id,
            'commentable_type': this.videoType,
            'filter': 'single',
          }).then((response) => {
            this.commentInput = ''
            this.comments.push(response.data.data)
          }).catch((error) => {
            console.log(error)
          })
        }
      },
      getComments: function() {
        if (!this.unauthorized) {
          axios.get('/api/comments', {
            params: {
              type: this.videoType,
              id: this.video.id
            }
          }).then((response) => {
            this.comments = response.data.data
          })
        }
      },
      deleteVideo: function() {
        if (confirm('Are you sure you want to delete this video titled "' + this.title + '"?')) {
          axios.delete('/api/scripts/' + this.video.id).then((response) => {
            this.videoDeleted = true;
            toastr.success('Video deleted.');
          }).catch((error) => {
            toastr.error('There was an error deleting this video.');
            console.error(error);
          })
        }
      }
    }
  }
</script>
