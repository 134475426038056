<template>
  <fragment>
    <div class="row align-items-end">
      <div class="form-group">
        <button class="btn btn-primary" @click="clearFilters()">Clear Filters</button>
      </div>
      <div class="form-group col-md-1">
        <label for="sport">Sport</label>
        <select id="sport" name="sport" class="form-control" v-model="filters.sport">
          <option value="">All</option>
          <option v-for="sport in sports" :key="sport.id" :value="sport.id">{{ prettifyString(sport.name) }}</option>
        </select>
      </div>
      <div class="form-group col-md-1">
        <label for="role">Role</label>
        <select id="role" class="form-control" v-model="filters.role">
          <option value="">All</option>
          <option v-for="role in roles" :key="role.id" :value="role.id">{{ prettifyString(role.name) }}</option>
        </select>
      </div>
      <div class="form-group col-md-2">
        <label for="subscription_platform">Subscription Platform</label>
        <select id="subscription_platform" class="form-control" v-model="filters.subscription_platform">
          <option value="">All</option>
          <option value="ios">iOS</option>
          <option value="android">Android</option>
          <option value="external">External</option>
          <option value="none">None</option>
        </select>
      </div>
      <div class="form-group col-md-2">
        <label for="subscription_status">Subscription Status</label>
        <select id="subscription_status" class="form-control" v-model="filters.subscription_status">
          <option value="">All</option>
          <option value="active">All Active</option>
          <option value="active_subscription">Active Subscription</option>
          <option value="active_trial_subscription">Active Trial</option>
          <option value="cancelled">Canceled</option>
          <option value="expired">Expired</option>
          <option value="manual">Manually Subscribed</option>
          <option value="no_subscription">No Subscription</option>
          <option value="unknown">Unknown</option>
        </select>
      </div>
      <div class="form-group col-md-2">
        <label for="plan_name">Subscription Plan</label>
        <select id="plan_name" class="form-control" v-model="filters.plan_name">
          <option value="">All</option>
          <option value="sds_sub_1mo">Monthly</option>
          <option value="sds_sub_1yr">Annual</option>
          <option value="mexico_camp">Mexico Camp</option>
        </select>
      </div>
      <div class="form-group col-md-1">
        <label for="plan_name">Search Name</label>
        <input type="text" class="form-control" v-model="name">
      </div>
      <div class="form-group col-md-2">
        <label for="plan_name">Search Email</label>
        <input type="email" class="form-control" v-model="email">
      </div>
    </div>
    <div class="row">

    </div>
  </fragment>
</template>

<script>
  import axios from 'axios';
  import {Fragment} from 'vue-frag';
  import _ from 'lodash';

  export default {
    name: 'UsersListFilters',
    components: {Fragment},
    inject: ['pagination', 'getUsers'],
    data() {
      return {
        filters: {
          sport: '',
          role: '',
          subscription_platform: '',
          subscription_status: '',
          plan_name: '',
        },
        name: '',
        email: '',
        roles: [],
        sports: []
      }
    },
    watch: {
      filters: {
        handler() {
          console.log('filters changed')
          this.filterResults();
        },
        deep: true
      },
      name: {
        handler: _.debounce(function() {
          console.log('name changed')
          this.filterResults();
        }, 1000),
        deep: true
      },
      email: {
        handler: _.debounce(function() {
          console.log('email changed')
          this.filterResults();
        }, 1000),
        deep: true
      },
    },
    created() {
      this.getRoles();
      this.getSports();
    },
    methods: {
      filterResults() {
        const activeUrl = this.pagination.links.find(link => link.active === true);
        let url = new URLSearchParams(activeUrl.url);

        let newFilterObj = {...this.filters, name: this.name, email: this.email};;
        url.set('filters', JSON.stringify(newFilterObj));
        url.set('page', 1);
        this.getUsers(decodeURIComponent(url.toString()), true);
      },
      getActiveUrlFilters() {
        const urlParams = new URLSearchParams(this.pagination.links.find(link => link.active === true)).get('filters');
        if (urlParams) {
          return JSON.parse(decodeURIComponent(urlParams));
        }
      },
      getRoles() {
        axios.get('/api/roles')
          .then(response => {
            this.roles = response.data.data;
          })
          .catch(error => {
            console.error(error);
          })
      },
      getSports() {
        axios.get('/api/sports')
          .then(response => {
            this.sports = response.data.data;
          })
          .catch(error => {
            console.error(error);
          })
      },
      prettifyString(string) {
        return string.toLowerCase().replace(/_/g, ' ').split(' ').map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
      },
      clearFilters() {
        this.filters = {
          sport: '',
          role: '',
          subscription_platform: '',
          subscription_status: '',
          plan_name: '',
          name: '',
          email: ''
        };
      }
    }
  }
</script>
