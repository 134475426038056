<template>
  <div class="web-nav">
    <ul class="nav nav-tabs tabs-bordered">
      <Fragment v-if="role !== 'fan'">
        <li class="nav-item">
          <TabControl
            @onClick="handleTabChange(tabKeys.attributes)"
            :active="activeTabKey === tabKeys.attributes"
          >
            Attributes
          </TabControl>
        </li>
        <li class="nav-item" v-if="player.has_slugger_science_profile">
          <TabControl
            @onClick="handleTabChangeToSluggerScience()"
            :active="activeTabKey === tabKeys.sluggerScience"
          >
            <img class="slugger-img" src="/build/images/SluggerScience.png" alt="" width="35" height="35">
            <span class="slugger-title">Slugger Science</span>
          </TabControl>
        </li>
        <!-- v-if="player.footage.data.film.length > 0 || organizationid == player.organization_id" -->
        <li class="nav-item">
          <TabControl
            @onClick="handleTabChange(tabKeys.proDayVideos)"
            :active="activeTabKey === tabKeys.proDayVideos"
          >
            {{ videoTabLabel }}
          </TabControl>
        </li>
        <li class="nav-item" v-if="player.footage.data.interviews.length > 0">
          <TabControl
            @onClick="handleTabChange(tabKeys.interview)"
            :active="activeTabKey === tabKeys.interview"
          >
            Interviews
          </TabControl>
        </li>
        <li class="nav-item table-section" v-if="isAuthorized">
          <TabControl
            @onClick="handleTabChange(tabKeys.notes)"
            :active="activeTabKey === tabKeys.notes"
          >
            Notes
          </TabControl>
        </li>
        <li class="nav-item table-section" v-if="['baseball','softball'].includes(sport) && isAuthorized">
          <TabControl
            @onClick="handleTabChange(tabKeys.events)"
            :active="activeTabKey === tabKeys.events"
          >
            Events
          </TabControl>
        </li>
      </Fragment>
      <Fragment v-else>
        <li class="nav-item">
          <TabControl
            @onClick="handleTabChange(tabKeys.attributes)"
            :active="activeTabKey === tabKeys.attributes"
          >
            Attributes
          </TabControl>
        </li>
      </Fragment>

    </ul>
  </div>
</template>

<script>
  import { Fragment } from 'vue-frag';
  import TabControl from '@components/PlayerProfile/components/DesktopNav/components/TabControl'

  export default {
    name: 'DesktopNav',
    components: { TabControl, Fragment },
    props: ['role', 'player', 'sport', 'activeTabKey', 'tabKeys', 'handleTabChange', 'organizationId', 'endorsements', 'isAuthorized', 'handleTabChangeToSluggerScience', 'addToProspectsBeforeSubscriptionEnded'],
    computed: {
      videoTabLabel() {
        switch (this.sport) {
          case 'football':
            return 'Pro Day Videos';
          case 'baseball':
          case 'softball':
            return 'Fundamentals';
          case 'mens_soccer':
          case 'womens_soccer':
            return 'Key Player Actions';
          default:
            return 'Position Drills/Custom'
        }
      }
    }
  }
</script>
