<template>
  <PlayerStatTilesPanel>
    <div v-for="question in questions">
      <div v-for="interview in interviews">
        <PlayerStatTile v-if="interview.questionId === question.id"
          :stat-name="question.name"
          :stat-value="question.value"
          :thumbnail-image="interview.thumbnailImage"
          :video="interview.video"
          :stat-question="interview.question"
        />
      </div>
    </div>
  </PlayerStatTilesPanel>
</template>

<script>
import PlayerStatTile from '@components/PlayerProfile/PublicProfile/components/PlayerStatTile';
import PlayerStatTilesPanel from '@components/PlayerProfile/PublicProfile/components/PlayerStatTilesPanel';

export default {
  name: 'InterviewsPanel',
  components: {PlayerStatTilesPanel, PlayerStatTile},
  inject: ['player'],
  computed: {
    interviews() {
      return Object.values(this.player?.interviews).map((interview) => {
        return {
          questionId: interview.question?.id,
          question: interview.question?.question,
          thumbnailImage: interview.file_upload?.thumbnail_path,
          video: interview.file_upload?.path
        }
      })
    },
    questions() {
      return Object.values(this.player?.questions).map((question, index) => {
        return {
          id: question.id,
          name: `Question ${index + 1}`,
          value: '',
          thumbnailImage: null,
          video: null
        }
      })
    }
  }
}
</script>
