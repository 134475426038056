<template>
  <form @submit.prevent="save()" id="add-footage">
    <input type="hidden" name="user_id" :value="playerId">
    <input type="hidden" name="organization_id" :value="organizationId">
    <div class="col-lg-12" v-if="! loading">
      <div class="row">
        <div class="col-md-12">
          <h4>Add Footage for this player</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 add-footage-position">
          <div class="form-group">
            <label>Positions</label>
            <select name="position_id" class="form-control select2" required @change="getScripts()" v-select2>
              <option v-for="position in positions" :value="position.id">{{ position.name }}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-12 col-md-6" v-if="scripts.length > 0">
          <div class="form-group">
            <label>Script</label>
            <select name="script_id" class="form-control select2" required>
              <option v-for="script in scripts" :value="script.id">{{ script.name }}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label>Video</label>
            <input type="file" name="video" class="form-control" accept="video/*" required>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button type="submit" class="btn btn-primary" :disabled="saving">{{ saving ? 'Saving...' : 'Save' }}</button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-12">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <span> Loading admin options</span>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
    import axios from 'axios'

    export default {

        name: 'AddFootage',
        props: {
          type: String,
          playerId: Number,
          organizationId: String,
          positions: Array,
          position_start: String
        },

        data: function() {
          return {
            loading: true,
            saving: false,
            scripts: [],
          }
        },

        mounted: function() {
          this.getScripts(true)
        },

        updated: function() {
          $('.select2').select2()
        },

        methods: {
          getScripts(start = false) {
            let scripts = !start ? $('.add-footage-position [name="position_id"]').val() : this.positions[0].id
            axios.get(`/api/script-crud?user_id=${this.playerId}&position_id=${scripts}`).then(response => {
              this.scripts = response.data.data
            }).then(() => {
              this.loading = false
            })
            axios.post(`/api/scripts/get-scripts?user_id=${this.playerId}&position_id=${scripts}`, {}).then(response => {
              window.vueInstance.$refs.player_profile.player.footage.film = response.data;
            })
          },
          save() {
            this.saving = true
            let formData = new FormData($('#add-footage')[0])
            axios.post('/api/scripts', formData).then(response => {
              if (response.data.data.file_upload !== undefined) {
                $('.add-film').show();
                $('.add-footage').hide();
                $('.no-footage').hide();
                $('.success').show();
                this.saving = false
              }
            }).catch(error => {
              console.log(error)
              this.saving = false
            })
          }
        }
    }
</script>
